.privacy {
    width: 100%;    
    background-color: #ffffff;
    color: #262626;
    font-size: 16px;
    line-height: 1.5;
    min-height: 100vh;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: var(--font-family-nunito);

    .otnotice-content {
        margin-left: 0;
        padding-left: 0;

        .otnotice-sections::after {
            content: "";
        }
    }

    .otnotice-sections {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
    }

    .banner {
        height: 135px;
        background-image: url('../../../public/banner2.svg');
        background-size: cover;
    }

    .title {
        font-size: 2.25rem;
        color: black;
        text-align: left;
        padding-top: 8vh;
        padding-left: 12%;
    }

    .privacy-body {
        * {
            font-family: var(--font-family-nunito) !important;
            font-size: 16px !important;

            a {
                color: var(--color-afya-blue) !important;
                font-weight: 400;

                * {
                    color: var(--color-afya-blue) !important;
                    font-weight: 400;
                }
            }
        }
    }

    .otnotice {
        font-size: 1rem;
        text-align:center;
        margin-top: 20px;
        margin-left: 8%;
        margin-right: 8%;


        a {
            color: #D31C5C;
            text-decoration: none;
        }
    }
}