@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Primary */
  --color-primary: #1D6F86;

  /* Neutral */
  --color-neutral-0: #FFFFFF;
  --color-neutral-100: #F3F7F9;
  --color-neutral-200: #D4DBDF;
  --color-neutral-300: #AFAFAF;
  --color-neutral-400: #7D7D7D;
  --color-neutral-500: #3d3c3c;
  --color-neutral-600: #475457;
  --color-neutral-700: #E4E7EC;
  --color-neutral-900: #232323;
  --color-neutral-1000: #c4c4c4;

   /* Red */
   --color-red-50: #fef3f2;
   --color-red-100: #f58d8f;
   --color-red-200: #f47072;
   --color-red-300: #f65a5c;
   --color-red-400: #f55154;
   --color-red-500: #ea2023;
   --color-red-600: #e32225;
   --color-red-700: #cf2224;
   --color-red-800: #991c1e;
   --color-red-900: #ab1f21;


  /* System */
  --color-system-error-100: #F9E0E0;
  --color-system-error-500: #DA3333;
  --color-system-error-600: #A92A14;

  --color-system-alert-100: #FEF4D9;
  --color-system-alert-500: #F7B500;

  --color-system-success-100: #DFF3E1;
  --color-system-success-500: #2DAC3A;

  --color-afya-blue-checked: #19174F;
  --color-afya-blue-checked-hover: #E5E5F1;

  /* passwordStrength */
  --color-gray-100: #E0E0E0;
  --color-red-200: #FF1744;
  --color-yellow-300: #FFC400;
  --color-green-400: #64DD17;
  --color-green-500: #0E8610;

  /*afya color */
  --color-afya-aqua: #1D6F86;
  --color-afya-aqua-hover: #0D5568;
  --color-afya-aqua-light: #E9F8FB;
  --color-afya-red: #DA3333;
  --color-afya-red-hover: #B82929;
  --color-afya-lightgray: #E6E6E6;
  --color-afya-lightgray-hover: #D6D6D6;
  --color-afya-lightgray-text: #B1B1B1;
  --color-afya-extralightgray: #FAFAFA;
  --color-afya-extralightgray-border: #E4E7EC;
  --color-afya-gray-text: #5C5F61;
  --color-afya-darkgray: #424242;
  --color-afya-pink: #CC0049;
  --color-afya-pink-hover: #9C073C;
  --color-afya-whiteblue-hover: #D6E9FF;
  --color-afya-blue: #0B63CB;
  --color-afya-blue-default: #024AA2;
  --color-afya-blue-default-hover: #003E8A;

  /*toast colors*/
  --color-toast-bg-warning: #FFF5E0;
  --color-toast-border-warning: #B25C00;
  --color-toast-text-warning: #8A4900;

  --font-afya: "Nunito Sans", sans-serif;

  /* image filters */
  --filter-icon-disabled: brightness(0) saturate(100%) invert(49%) sepia(0%) saturate(0%) hue-rotate(254deg) brightness(100%) contrast(94%);
}

.reportClass {
  height: 100vh;
  width: 100%;
}

.form-select {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAAQtJREFUOBG1lEEOgjAQRalbGj2OG9caOACn4ALGtfEuHACiazceR1PWOH/CNA3aMiTaBDpt/7zPdBKy7M/DCL9pGkvxxVp7KsvyJftL5rZt1865M+Ucq6pyyF3hNcI7Cuu+728QYn/JQA5yKaempxuZmQngOwEaYx55nu+1lQh8GIatMGi+01NwBcEmhxBqK4nAPZJ78K0KKFAJmR3oPp8+Iwgob0Oa6+TLoeCvRx+mTUYf/FVBGTPRwDkfLxnaSrRwcH0FWhNOmrkWYbE2XEicqgSa1J0LQ+aPCuQgZiLnwewbGuz5MGoAhcIkCQcjaTBjMgtXGURMVHC1wcQEy0J+Zlj8bKAnY1/UzDe2dbAVqfXn6wAAAABJRU5ErkJggg==');
  background-size: 0.7rem;
  background-position: right 0.7rem center;
}

.text-message-error {
  color: var(--color-system-error-500);
  font-size: .8em;
  margin-top: 8px;
}

.delete-icon {
  filter: var(--current-filter, none);
}


.group:disabled .delete-icon {
  --current-filter: var(--filter-icon-disabled);
}

.image-invert {filter: invert(100%) grayscale()}

.shadow-submenu .image-invert {
  filter: none
}

.scrollbar-thin::-webkit-scrollbar {
  width: 12px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: var(--color-neutral-100);
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--color-neutral-300);
  border-radius: 10px;
  border: 3px solid var(--color-neutral-300);
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-neutral-300);
}